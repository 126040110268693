import { Vue, Options } from 'vue-class-component';

import { qrCode } from 'ionicons/icons';
import { PropType } from 'vue';
import { RegisterModel } from '../../models/RegisterModel';
import { alertController, IonModal } from '@ionic/vue';
import QrCodeModal from './QRCodeModal/QRCodeModal.vue';
import IconExchange from '@/views/components/icons/IconExchange.vue';
import transferClient from '@/core/api/transfer-service';
import {
    IPatientTransferTokenValidationRequest,
    PatientTransferTokenValidationRequest,
} from '@/../client-generator/generated-clients/api-clients';

@Options({
    name: 'TransferManagement',
    props: {
        registerModel: {
            type: Object as PropType<RegisterModel>,
            required: true,
        },
    },
    emits: ['goNext'],
    components: {
        IonModal,
        QrCodeModal,
        IconExchange,
    },
})
export default class TransferManagement extends Vue {
    qrCode = qrCode;
    registerModel!: RegisterModel;
    manualTransferCode = '';
    minManualTransferCodeLength = 7;
    showQRScan = false;
    qrCodeRead = false;

    get nextDisabled() {
        return !this.qrCodeRead && (!this.manualTransferCode || this.manualTransferCode.length < this.minManualTransferCodeLength);
    }

    mounted() {
        this.registerModel.tempData.transferCode = '';
        this.manualTransferCode = '';
    }

    toggleScanQR(doShow: boolean) {
        if (doShow) this.qrCodeRead = false;
        this.showQRScan = doShow;
    }

    receivedCode(code: string) {
        this.registerModel.tempData.transferCode = code;
        this.qrCodeRead = true;
        this.manualTransferCode = '';
        this.toggleScanQR(false);
    }

    async goNext() {
        if (!this.qrCodeRead) {
            this.registerModel.tempData.transferCode = this.manualTransferCode;
        }
        const request: IPatientTransferTokenValidationRequest = {
            token: this.registerModel.tempData.transferCode,
        };
        try {
            const response = await transferClient.tokenValidation(new PatientTransferTokenValidationRequest(request));
            this.registerModel.patient.firstName = response.patientFirstName;
            this.registerModel.patient.lastName = response.patientLastName;
            this.registerModel.patient.fiscalCode = response.patientFiscalCode.toLocaleUpperCase();
            this.$emit('go-next');
        } catch (error) {
            let message = { title: '', message: '' };
            if (error.status === 400) {
                message = { title: this.$t('invalidCode'), message: this.$t('invalidCodeText') };
            } else {
                message = { title: this.$t('generalError'), message: this.$t('tryLater') };
            }
            const alert = await alertController.create({
                header: message.title,
                message: message.message,
                buttons: [this.$t('ok')],
            });
            return alert.present();
        }
    }
}
