<template>
    <svg
        width="68px"
        height="48px"
        viewBox="0 0 68 48"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <title>exchange-light</title>
        <defs>
            <filter id="filter-1">
                <feColorMatrix
                    in="SourceGraphic"
                    type="matrix"
                    values="0 0 0 0 0.090196 0 0 0 0 0.776471 0 0 0 0 0.443137 0 0 0 1.000000 0"
                ></feColorMatrix>
            </filter>
        </defs>
        <g
            id="FAMILIARE"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
        >
            <g
                id="exchange-light"
                transform="translate(-154.000000, -228.000000)"
                filter="url(#filter-1)"
            >
                <g transform="translate(154.000000, 228.000000)">
                    <path
                        d="M67.5331641,14.6719312 L55.25,26.6719638 C54.6275078,27.2831828 53.6185312,27.2831828 52.9961719,26.6719638 L52.0570547,25.7497874 C51.4345625,25.1385683 51.4345625,24.1475221 52.0570547,23.5361727 L60.1498516,15.6521512 L1.59375,15.6521512 C0.713601562,15.6521512 0,14.9513232 0,14.0869296 L0,13.0434485 C0,12.1790548 0.713601562,11.4782268 1.59375,11.4782268 L60.1514453,11.4782268 L52.0570547,3.59420542 C51.4345625,2.98298637 51.4345625,1.9919402 52.0570547,1.38059071 L52.9960391,0.458414289 C53.6185312,-0.152804763 54.6275078,-0.152804763 55.25,0.458414289 L67.5331641,12.4584469 C68.1556562,13.069666 68.1555234,14.0607121 67.5331641,14.6719312 Z M0.466835937,35.5415531 L12.75,47.5415857 C13.3724922,48.1528048 14.3814687,48.1528048 15.0039609,47.5415857 L15.9429453,46.6194093 C16.5654375,46.0081902 16.5654375,45.0171441 15.9429453,44.4057946 L7.84855469,36.5217732 L66.40625,36.5217732 C67.2863984,36.5217732 68,35.8209452 68,34.9565515 L68,33.9130704 C68,33.0486768 67.2863984,32.3478488 66.40625,32.3478488 L7.85014844,32.3478488 L15.9429453,24.4638273 C16.5654375,23.8526083 16.5654375,22.8615621 15.9429453,22.2502126 L15.0038281,21.3280362 C14.3814687,20.7168172 13.3723594,20.7166867 12.75,21.3280362 L0.466835937,33.3280688 C-0.155523438,33.9392879 -0.15565625,34.930334 0.466835937,35.5415531 Z"
                        id="Shape"
                        fill="#000000"
                        fill-rule="nonzero"
                    ></path>
                </g>
            </g>
        </g>
    </svg>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";

@Options({
    name: "IconExchange",
})
export default class IconExchange extends Vue {}
</script>
