import { Vue, Options } from 'vue-class-component';
import { BrowserMultiFormatReader } from '@zxing/library';

@Options({
    name: 'QRCodeScanner',
    emits: ['hasBarcode'],
})
export default class QRCodeScanner extends Vue {
    reader: BrowserMultiFormatReader | null = null;
    devices: MediaDeviceInfo[] = [];
    selectedDeviceId: string | null = '';
    cameraError = false;

    async mounted() {
        this.reader = new BrowserMultiFormatReader();
        try {
            this.devices = await this.reader.listVideoInputDevices();
            if (this.devices.length) {
                this.selectedDeviceId = this.devices[0].deviceId;
                await this.doScan();
            } else {
                this.cameraError = true;
            }
        } catch (error) {
            this.cameraError = true;
            console.log(error.toString());
        }
    }

    unmounted() {
        this.doReset();
    }

    async doScan() {
        if (!(this.reader || this.selectedDeviceId)) return;
        this.cameraError = false;

        (this.reader as BrowserMultiFormatReader)
            .decodeOnceFromVideoDevice(this.selectedDeviceId as string, 'video')
            .then((result) => {
                this.$emit('has-barcode', result);
            })
            .catch((err) => {
                this.cameraError = true;
                console.error(err);
            });
    }

    doReset() {
        (this.reader as BrowserMultiFormatReader).reset();
    }
}
