import { RelativeRegistrationDocumentType, RelativeRegistrationRelation } from '@/../client-generator/generated-clients/api-clients';
import { ActivationType, PatientPlace } from './RegisterEnums';

export interface Document {
    base64: string;
    webPath: string;
    fileName: string;
}

export interface Requestor {
    firstName: string;
    lastName: string;
    fiscalCode: string;
    phone: string;
    email: string;
    confirmEmail: string;
    password: string;
    confirmPassword: string;
    documentType: RelativeRegistrationDocumentType | null;
}

export interface Patient {
    firstName: string;
    lastName: string;
    fiscalCode: string;
    unit: number | null;
    relation: RelativeRegistrationRelation | null;
}

export interface RegisterModel {
    requestor: Requestor;
    requestorDocuments: {
        documentFront: Document | null;
        documentBack: Document | null;
        selfieImage: Document | null;
    };
    tempData: {
        smsOtp: string;
        activationType: ActivationType | '';
        patientPlace: PatientPlace | '';
        transferCode: string;
    };
    smsToken: string;
    patient: Patient;
    confirmCriminalPenalties: boolean;
    confirmClosestRelative: boolean;
    confirmPersonalData: boolean;
    confirmPrivacy: boolean;
    confirmEmail: boolean;
    confirmDataIsCorrect: boolean;
    confirmServiceActivation: boolean;
    confirmTermsAndAgreements: boolean;
}

export const DefaultRegisterModel: RegisterModel = {
    requestor: {
        firstName: '',
        lastName: '',
        fiscalCode: '',
        phone: '',
        email: '',
        confirmEmail: '',
        password: '',
        confirmPassword: '',
        documentType: null,
    },
    requestorDocuments: {
        documentFront: null,
        documentBack: null,
        selfieImage: null,
    },
    tempData: {
        smsOtp: '',
        activationType: '',
        patientPlace: '',
        transferCode: '',
    },
    patient: {
        firstName: '',
        lastName: '',
        fiscalCode: '',
        unit: null,
        relation: null,
    },
    smsToken: '',
    confirmCriminalPenalties: false,
    confirmClosestRelative: false,
    confirmPersonalData: false,
    confirmPrivacy: false,
    confirmEmail: false,
    confirmDataIsCorrect: false,
    confirmServiceActivation: false,
    confirmTermsAndAgreements: false,
};
