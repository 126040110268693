export enum ActivationType {
    TRANSFER,
    NEW,
}

export enum PatientPlace {
    HOSPITAL_UNIT,
    EMERGENCY_ROOM,
}

export enum WizardStep {
    REQUESTOR_DATA,
    OTP,
    ACTIVATION_TYPE,
    QR_CODE,
    REQUESTOR_RELATIONSHIP,
    OVERVIEW,
    THANKYOU,
}
