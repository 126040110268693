<template>
    <div class="barcode-scanner mt-3">
        <video
            v-if="!cameraError"
            id="video"
            class="barcode-scanner__video"
        ></video>
        <div class="alert alert-danger" v-else>
            {{ $t("qrCameraError") }}
        </div>
    </div>
</template>

<script lang="ts" src="./QRCodeScanner.ts"></script>

<style lang="scss" src="./QRCodeScanner.scss"></style>
