import { Vue, Options } from 'vue-class-component';
import { checkmarkCircleOutline as checkMarkCircle } from 'ionicons/icons';

@Options({
    name: 'StepThankyou',
    props: {
        backToHome: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
})
export default class StepThankyou extends Vue {
    checkMarkCircle = checkMarkCircle;
    backToHome!: boolean;

    goTo(route: string) {
        this.$router.replace({
            name: route,
        });
    }
}
