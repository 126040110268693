<template>
    <ion-page>
        <ion-header>
            <ion-toolbar>
                <ion-buttons slot="primary">
                    <ion-button
                        class="button-close"
                        color="danger"
                        @click="handleClose()"
                    >
                        <IconTimes />
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>
        <ion-content :fullscreen="true">
            <QRCodeScanner @has-barcode="handleBarcodeScan" />
        </ion-content>
    </ion-page>
</template>

<script lang="ts" src="./QRCodeModal.ts"></script>
<style lang="scss" src="./QRCodeModal.scss"></style>
