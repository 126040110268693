<template>
    <ion-card class="activation-type">
        <ion-card-content>
            <ion-radio-group
                :allowEmptySelection="true"
                v-model="registerModel.tempData.activationType"
            >
                <ion-item class="ion-no-padding d-flex align-items-start">
                    <ion-label
                        v-html="$t('registerWizard.newActivation')"
                        class="ion-text-wrap"
                    />
                    <ion-radio
                        class="mt-3"
                        mode="md"
                        slot="start"
                        :value="ActivationType.NEW"
                    />
                </ion-item>

                <ion-item class="ion-no-padding d-flex align-items-start">
                    <ion-label
                        v-html="$t('registerWizard.transferPatient')"
                        class="ion-text-wrap"
                    />
                    <ion-radio
                        class="mt-3"
                        mode="md"
                        slot="start"
                        :value="ActivationType.TRANSFER"
                    />
                </ion-item>
            </ion-radio-group>

            <div
                class="gray-card"
                v-if="
                    registerModel.tempData.activationType === ActivationType.NEW
                "
            >
                <div class="mb-2 gray-card__title">
                    {{ $t("registerWizard.patientPlace") }}
                </div>
                <ion-radio-group
                    :allowEmptySelection="true"
                    v-model="registerModel.tempData.patientPlace"
                >
                    <ion-item
                        color="#eaeaea"
                        lines="none"
                        class="ion-no-padding"
                    >
                        <ion-label
                            class="ion-text-wrap"
                            v-html="$t('registerWizard.hospitalUnit')"
                        />
                        <ion-radio
                            mode="md"
                            slot="start"
                            :value="PatientPlace.HOSPITAL_UNIT"
                        />
                    </ion-item>

                    <ion-item
                        color="#eaeaea"
                        class="ion-no-padding"
                        lines="none"
                    >
                        <ion-label
                            class="ion-text-wrap"
                            v-html="$t('registerWizard.emergencyRoom')"
                        />
                        <ion-radio
                            mode="md"
                            slot="start"
                            :value="PatientPlace.EMERGENCY_ROOM"
                        />
                    </ion-item>
                </ion-radio-group>
            </div>

            <ion-button
                color="primary"
                expand="block"
                class="mx-0 mt-4"
                :disabled="nextButtonDisabled"
                @click="nextStep()"
            >
                {{ $t("registerWizard.next") }}
            </ion-button>
        </ion-card-content>
    </ion-card>
</template>

<script lang="ts" src="./StepActivationType.ts"></script>
<style lang="scss" src="./StepActivationType.scss"></style>
