import { Vue, Options } from 'vue-class-component';

import { IonImg, IonHeader, IonToolbar, IonButtons } from '@ionic/vue';
import IconTimes from '@/views/components/icons/IconTimes.vue';
import QRCodeScanner from '@/views/components/QRCodeScanner/QRCodeScanner.vue';
import { Result } from '@zxing/library';

@Options({
    name: 'QRCodeModal',
    components: {
        IonHeader,
        IonImg,
        IonToolbar,
        IonButtons,
        IconTimes,
        QRCodeScanner,
    },
    emits: ['close', 'receivedCode'],
})
export default class QRCodeModal extends Vue {
    handleClose() {
        this.$emit('close');
    }

    handleBarcodeScan(result: Result) {
        this.$emit('received-code', result.getText());
    }
}
