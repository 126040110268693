<template>
    <ion-card>
        <ion-card-content class="pt-0">
            <VForm
                :initialValues="registerModel.patient"
                :validation-schema="validationSchema"
                v-slot="{ values, validate }"
            >
                <div class="requestor-relationship__card gray-card">
                    <div class="requestor-relationship__label mb-3">
                        {{ $t("registerWizard.declareToBe") }}
                    </div>
                    <ion-item
                        class="ion-no-padding"
                        color="#eaeaea"
                        lines="none"
                    >
                        <VField
                            name="relation"
                            v-model="registerModel.patient.relation"
                            :label="$t('registerWizard.relationGrade')"
                            v-slot="{ field }"
                        >
                            <ion-label
                                position="stacked"
                                class="requestor-relationship__card-title mb-3"
                            >
                                {{ $t("registerWizard.relationGrade") }}
                            </ion-label>
                            <ion-select
                                class="custom-input"
                                value="{hairColor}"
                                :placeholder="$t('select')"
                                v-bind="field"
                                :ok-text="$t('select')"
                                :cancel-text="$t('cancel')"
                                name="relation"
                            >
                                <ion-select-option :value="RelationType.Spouse">
                                    {{
                                        $t("registerWizard.relationType.spouse")
                                    }}
                                </ion-select-option>
                                <ion-select-option :value="RelationType.Son">
                                    {{ $t("registerWizard.relationType.son") }}
                                </ion-select-option>
                                <ion-select-option :value="RelationType.Parent">
                                    {{
                                        $t("registerWizard.relationType.parent")
                                    }}
                                </ion-select-option>
                                <ion-select-option
                                    :value="RelationType.Sibling"
                                >
                                    {{
                                        $t(
                                            "registerWizard.relationType.sibling"
                                        )
                                    }}
                                </ion-select-option>
                                <ion-select-option :value="RelationType.Tutor">
                                    {{
                                        $t("registerWizard.relationType.tutor")
                                    }}
                                </ion-select-option>
                                <ion-select-option
                                    :value="RelationType.Cohabitant"
                                >
                                    {{
                                        $t(
                                            "registerWizard.relationType.cohabitant"
                                        )
                                    }}
                                </ion-select-option>
                                <ion-select-option :value="RelationType.Other">
                                    {{
                                        $t("registerWizard.relationType.other")
                                    }}
                                </ion-select-option>
                            </ion-select>
                        </VField>
                    </ion-item>
                    <VErrorMessage name="relation" />
                </div>

                <div class="requestor-relationship__card gray-card">
                    <div class="requestor-relationship__label mb-3">
                        {{ $t("registerWizard.patientData") }}
                    </div>
                    <ion-item
                        class="ion-no-padding"
                        color="#eaeaea"
                        lines="none"
                    >
                        <VField
                            name="firstName"
                            v-model="registerModel.patient.firstName"
                            :label="$t('registerWizard.patientFirstName')"
                            v-slot="{ field }"
                        >
                            <ion-label
                                position="stacked"
                                class="requestor-relationship__card-title mb-3"
                            >
                                {{ $t("registerWizard.patientFirstName") }}
                            </ion-label>
                            <ion-input
                                :disabled="patientInputDisabled"
                                class="custom-input"
                                type="email"
                                v-bind="field"
                                name="firstName"
                            />
                        </VField>
                    </ion-item>
                    <VErrorMessage name="firstName" />

                    <ion-item
                        class="ion-no-padding"
                        color="#eaeaea"
                        lines="none"
                    >
                        <VField
                            name="lastName"
                            v-model="registerModel.patient.lastName"
                            :label="$t('registerWizard.patientLastName')"
                            v-slot="{ field }"
                        >
                            <ion-label
                                position="stacked"
                                class="requestor-relationship__card-title mb-3"
                            >
                                {{ $t("registerWizard.patientLastName") }}
                            </ion-label>
                            <ion-input
                                :disabled="patientInputDisabled"
                                class="custom-input"
                                type="email"
                                v-bind="field"
                                name="lastName"
                            />
                        </VField>
                    </ion-item>
                    <VErrorMessage name="lastName" />

                    <ion-item
                        class="ion-no-padding"
                        color="#eaeaea"
                        lines="none"
                    >
                        <VField
                            name="fiscalCode"
                            v-model="registerModel.patient.fiscalCode"
                            :label="$t('registerWizard.patientFiscalCode')"
                            v-slot="{ field }"
                        >
                            <ion-label
                                position="stacked"
                                class="requestor-relationship__card-title mb-3"
                            >
                                {{ $t("registerWizard.patientFiscalCode") }}
                            </ion-label>
                            <ion-input
                                :disabled="patientInputDisabled"
                                class="custom-input"
                                type="email"
                                v-bind="field"
                                maxlength="16"
                                name="fiscalCode"
                            />
                        </VField>
                    </ion-item>
                    <VErrorMessage name="fiscalCode" />
                </div>

                <div class="requestor-relationship__label mt-4">
                    {{ $t("registerWizard.alsoDeclare") }}
                </div>

                <ion-item class="ion-no-padding align-items-start">
                    <ion-label
                        class="ion-text-wrap"
                        v-html="$t('registerWizard.confirmCriminalPenalties')"
                    />
                    <ion-checkbox
                        color="success"
                        mode="md"
                        class="mt-3"
                        v-model="registerModel.confirmCriminalPenalties"
                        slot="start"
                    />
                </ion-item>

                <ion-item class="ion-no-padding align-items-start">
                    <ion-label
                        class="ion-text-wrap"
                        v-html="$t('registerWizard.confirmClosestRelative')"
                    />
                    <ion-checkbox
                        color="success"
                        mode="md"
                        class="mt-3"
                        v-model="registerModel.confirmClosestRelative"
                        slot="start"
                    />
                </ion-item>

                <ion-item class="ion-no-padding align-items-start">
                    <ion-label class="ion-text-wrap">
                        <span
                            v-html="$t('registerWizard.confirmPersonalData')"
                        />
                        <a class="link" :href="privacyPolicyUrl" target="_blank"
                            >{{ $t("registerWizard.privacyPolicy") }} </a
                        ><span>&#41;;</span>
                    </ion-label>
                    <ion-checkbox
                        color="success"
                        mode="md"
                        class="mt-3"
                        v-model="registerModel.confirmPersonalData"
                        slot="start"
                    />
                </ion-item>

                <ion-item class="ion-no-padding align-items-start">
                    <ion-label
                        v-html="$t('registerWizard.confirmPrivacy')"
                        class="ion-text-wrap"
                    />

                    <ion-checkbox
                        color="success"
                        mode="md"
                        class="mt-3"
                        v-model="registerModel.confirmPrivacy"
                        slot="start"
                    />
                </ion-item>

                <ion-item class="ion-no-padding align-items-start">
                    <ion-label class="ion-text-wrap">
                        <span v-html="$t('registerWizard.confirmEmailText')" />
                        <strong>{{ registerModel.requestor.email }}</strong>
                    </ion-label>
                    <ion-checkbox
                        color="success"
                        mode="md"
                        class="mt-3"
                        v-model="registerModel.confirmEmail"
                        slot="start"
                    />
                </ion-item>

                <ion-button
                    color="primary"
                    expand="block"
                    class="mx-0 mt-4"
                    @click="submit(values, validate)"
                >
                    {{ $t("registerWizard.next") }}
                </ion-button>
            </VForm>
        </ion-card-content>
    </ion-card>
</template>

<script lang="ts" src="./StepRequestorRelationship.ts"></script>
<style lang="scss" src="./StepRequestorRelationship.scss"></style>
