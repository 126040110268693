import { Vue, Options } from 'vue-class-component';
import { ActivationType, PatientPlace, WizardStep } from '../../models/RegisterEnums';
import { RegisterModel } from '../../models/RegisterModel';

@Options({
    name: 'StepActivationType',
    emits: ['go-next', 'change-step'],
    props: {
        registerModel: {
            type: Object as () => RegisterModel,
            required: true,
        },
    },
})
export default class StepActivationType extends Vue {
    registerModel!: RegisterModel;
    ActivationType = ActivationType;
    PatientPlace = PatientPlace;

    get nextButtonDisabled() {
        return (
            this.registerModel.tempData.activationType === '' ||
            (this.registerModel.tempData.activationType === this.ActivationType.NEW && this.registerModel.tempData.patientPlace === '')
        );
    }

    nextStep() {
        if (this.registerModel.tempData.activationType === this.ActivationType.NEW) {
            this.$emit('change-step', WizardStep.REQUESTOR_RELATIONSHIP);
        } else {
            this.$emit('change-step', WizardStep.QR_CODE);
        }
    }
}
