<template>
    <ion-card class="mt-5 thankyou">
        <ion-card-content>
            <div class="thankyou__icon mt-2">
                <ion-icon :icon="checkMarkCircle" />
            </div>
            <h2 class="thankyou__title mt-1">
                {{ $t("registerWizard.requestSuccess") }}
            </h2>

            <div
                v-if="!backToHome"
                class="thankyou__desc mt-3"
                v-html="$t('registerWizard.requestSuccessText')"
            />

            <div
                v-else
                class="thankyou__desc mt-3"
                v-html="$t('newPatient.successDesc')"
            />

            <div class="thankyou__desc mt-4">
                {{ $t("registerWizard.needInfo") }}
                <a class="link" href="mailto:">{{
                    $t("registerWizard.writeUs")
                }}</a>
            </div>

            <ion-button
                v-if="!backToHome"
                class="w-100 mt-5 mb-3"
                @click="goTo('page-login')"
            >
                {{ $t("registerWizard.accessService") }}
            </ion-button>

            <ion-button
                v-else
                class="w-100 mt-5 mb-3"
                @click="goTo('page-relatives-list')"
            >
                {{ $t("newPatient.backToHome") }}
            </ion-button>
        </ion-card-content>
    </ion-card>
</template>

<script lang="ts" src="./StepThankyou.ts"></script>
<style lang="scss" src="./StepThankyou.scss"></style>
