import { RelativeRegistrationRelation } from '@/../client-generator/generated-clients/api-clients';
import { alertController } from '@ionic/vue';
import { Vue, Options } from 'vue-class-component';
import { Patient, RegisterModel } from '../../models/RegisterModel';

@Options({
    name: 'StepRequestorRelationship',
    emits: ['go-next'],
    props: {
        registerModel: {
            type: Object as () => RegisterModel,
            required: true,
        },
    },
})
export default class StepRequestorRelationship extends Vue {
    registerModel!: RegisterModel;
    RelationType = RelativeRegistrationRelation;

    privacyPolicyUrl = process.env.VUE_APP_PRIVACY_POLICY_URL;

    validationSchema = {
        firstName: 'required',
        lastName: 'required',
        fiscalCode: 'required|min:16|fiscalCode',
        relation: 'required',
    };

    get patientInputDisabled() {
        return this.registerModel.tempData.transferCode !== '';
    }

    async submit(values: Patient, validateForm: Function) {
        const isValid = await validateForm();
        if (
            !(
                isValid &&
                this.registerModel.confirmClosestRelative &&
                this.registerModel.confirmPersonalData &&
                this.registerModel.confirmPrivacy &&
                this.registerModel.confirmEmail
            )
        ) {
            const alert = await alertController.create({
                header: this.$t('warning'),
                message: this.$t('requiredFieldsWarn'),
                buttons: [this.$t('ok')],
            });
            return alert.present();
        } else {
            this.registerModel.patient = { ...values };
            this.$emit('go-next');
        }
    }
}
