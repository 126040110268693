<template>
    <ion-card class="transfer-management mt-5">
        <ion-card-content>
            <IconExchange class="mx-auto d-block mb-3" />

            <ion-card-title class="text-center mb-4">
                {{ $t("registerWizard.transferManagmentStep.title") }}
            </ion-card-title>
            <p
                class="text-center mb-2"
                v-html="
                    $t('registerWizard.transferManagmentStep.qrDescription')
                "
            ></p>
            <!-- Icons -->
            <div class="text-center">
                <ion-button
                    fill="outline"
                    :class="{ 'text-black': !qrCodeRead }"
                    @click="toggleScanQR(true)"
                >
                    <ion-icon slot="start" :icon="qrCode"></ion-icon>
                    {{
                        $t(
                            `registerWizard.transferManagmentStep.${
                                !qrCodeRead ? "scanQr" : "codeRecognized"
                            }`
                        )
                    }}
                </ion-button>
            </div>
            <template v-if="!qrCodeRead">
                <hr class="my-4" />
                <p
                    class="text-center"
                    v-html="
                        $t(
                            'registerWizard.transferManagmentStep.manualDescription'
                        )
                    "
                ></p>
                <ion-item class="ion-no-padding mx-auto mt-2 code-input">
                    <ion-input
                        type="text"
                        :placeholder="$t('registerWizard.otpStep.insertCode')"
                        v-model="manualTransferCode"
                        class="is-valid"
                    ></ion-input>
                </ion-item>
            </template>
            <ion-button
                color="primary"
                class="w-100 mt-4"
                :disabled="nextDisabled"
                @click="goNext"
            >
                {{ $t("activate") }}
            </ion-button>
        </ion-card-content>
    </ion-card>

    <ion-modal :is-open="showQRScan" @onDidDismiss="toggleScanQR(false)">
        <QrCodeModal
            @close="toggleScanQR(false)"
            @receivedCode="receivedCode"
        />
    </ion-modal>
</template>

<script lang="ts" src="./TransferManagement.ts"></script>
<style lang="scss" src="./TransferManagement.scss"></style>
